@import "transition";

@mixin svg-color($color: currentColor,$transition: true, $opacity: 1) {
  .fill {
    @if ($transition) {
      @include transition();
    }
    fill: $color;
    fill-opacity: $opacity;
  }
  .stroke {
    @if ($transition) {
      @include transition();
    }
    stroke: $color;
    stroke-opacity: $opacity;
  }
}
