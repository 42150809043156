@use '../../../../@bizkeytech/base/assets/css/index' as css;

html {

  .redirect-link-view {
    height: calc(100vh);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}
