@use "sass:math";
@import "../functions/theme-var";

@mixin custom-scrollbar(
  $force-webkit: false,
  $size: 7px,
  $track-color: theme-var(palette-app-specific-scrollbar-track-color),
  $thumb-color: theme-var(palette-app-specific-scrollbar-thumb-color),
) {

  @if ($force-webkit) {
  } @else {
    scrollbar-color: $thumb-color $track-color;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none !important;
    transition: none !important;
    width: $size !important;
    height: $size !important;
    margin: 0 $size !important;
  }

  &::-webkit-scrollbar-track {
    background: $track-color !important;
    border-radius: math.div($size, 2) !important;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color !important;
    border: 1px solid $thumb-color !important;
    border-radius: math.div($size, 2) !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-button {
    display: none;
    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
    display: none;
  }
}
