@use "../../../assets/css" as css;

:root {
  --toastify-toast-min-height: #{css.theme-var(app-specific-toast-min-height)} !important;
  --toastify-toast-width: #{css.theme-var(app-specific-toast-width)} !important;
  --toastify-font-family: #{css.theme-var(app-specific-toast-font-family)} !important;
  --toastify-text-color-dark: #{css.theme-var(palette-app-specific-toast-text-color)} !important;
  --toastify-text-color-light: #{css.theme-var(palette-app-specific-toast-text-color)} !important;
  --toastify-color-dark: #{css.theme-var(palette-app-specific-toast-color)} !important;
  --toastify-color-light: #{css.theme-var(palette-app-specific-toast-color)} !important;
}

html {
  .Toastify__toast {
    border-radius: css.theme-var(shape-borderRadius);
    max-width: css.theme-var(app-specific-toast-max-width);
    box-shadow: none;
    border: 1px solid rgba(css.theme-var(palette-text-primaryChannel) / 0.1);

    .toast-content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
}
