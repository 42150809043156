@import "variables";
@import "mixins";
@import "functions";

html {
  text-rendering: auto;

  p {
    margin-block: 0;
  }

  button {
    cursor: pointer;
  }

  // Used for the action button SVGs background color
  .fill-bg {
    fill: $data-grid-container-background-color
  }

  .data-grid-container {
    @include font-family($data-grid-primary-font-family);
    //@include svg-color($data-grid-icon-color);
    background-color: $data-grid-container-background-color;
    width: 100%;
    padding: 0 0.75rem;
    border-radius: $data-grid-container-border-radius;
    position: relative;

    //* {
    //  @include custom-scrollbar();
    //}

    .data-grid-toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: fit-content;
      padding-top: 2rem;

      .data-grid-toolbar-action {
        @include svg-color($data-grid-toolbar-action-color);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $data-grid-toolbar-action-color;
        background-color: $data-grid-toolbar-action-background-color;
        border: 1px solid $data-grid-toolbar-action-border-color;
        font-weight: 600;
        border-radius: $data-grid-toolbar-button-border-radius;
        padding: 0.25rem 0.75rem;
        margin-right: 0.5rem;

        &.data-grid-icon-button {
          padding: 0.25rem;
          border-radius: 50%;
        }

        p {
          margin-left: 0.1rem;
        }

        .fill-alternate {
          fill: $white;
        }

        &:hover {
          @include svg-color($data-grid-toolbar-active-action-color);
          color: $data-grid-toolbar-active-action-color;
          background-color: $data-grid-toolbar-active-action-background-color;
          border-color: $data-grid-toolbar-active-action-border-color;

          .fill-alternate {
            fill: $white;
          }
        }

        &:disabled {
          @include svg-color($data-grid-toolbar-disabled-action-color);
          color: $data-grid-toolbar-disabled-action-color;
          background-color: $data-grid-toolbar-disabled-action-background-color;
          border-color: $data-grid-toolbar-disabled-action-border-color;
        }
      }

    }

    .data-grid-layout {
      width: 100%;
      overflow-x: auto;
      border-radius: $data-grid-layout-border-radius $data-grid-layout-border-radius 0 0;
      position: relative;
      margin-top: 1rem;

      .data-grid-table {
        table-layout: fixed;
        border-radius: $data-grid-table-border-radius;
        border-collapse: separate;
        background-color: transparent;
        border-spacing: 0;
        border: none;

        .data-grid-header {
          $icon-size: 24px;
          $content-padding-size: 10px;
          position: sticky;
          top: 0;
          z-index: 3;
          background-color: $data-grid-header-background-color;
          border-radius: $data-grid-header-border-radius $data-grid-header-border-radius 0 0;

          .data-grid-header-cell {
            $icon-widths: 24px + 28px;
            min-width: fit-content;
            padding: 0.5rem 0;
            z-index: 1;
            background-color: $data-grid-header-background-color;

            &:first-of-type {
              border-radius: $data-grid-header-border-radius 0 0 0;
            }

            &:last-of-type {
              border-radius: 0 $data-grid-header-border-radius 0 0;
            }

            .data-grid-header-inner-cell {
              min-height: calc(56px - 1rem);
              position: relative;
              display: flex;
              align-items: center;
              width: 100%;
              overflow-x: hidden;
              background-color: $data-grid-header-background-color;


              .data-grid-header-inner-cell-content {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;

                .data-grid-header-title {
                  @include rfs($text-sm);
                  font-weight: 500;
                  color: $data-grid-header-title-color;
                  overflow-x: hidden;
                  text-overflow: ellipsis;
                  overflow-wrap: break-word;
                  white-space: nowrap;
                }

                .data-grid-header-actions-icon-button-container {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  flex-grow: 1;

                  .data-grid-header-actions-icon-button {
                    @include transition((opacity, color, background-color));
                    @include svg-color($data-grid-header-actions-icon-color);
                    opacity: 0;
                    padding: 0;
                    border-radius: 50%;
                    background-color: $data-grid-header-actions-icon-background-color;
                    min-width: $icon-size;
                    width: $icon-size;
                    height: $icon-size;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                      @include transition(all);
                    }

                    &:hover {
                      background-color: $data-grid-header-hovered-actions-icon-background-color;
                      @include svg-color($data-grid-header-hovered-actions-icon-color);
                    }

                    &.active {
                      opacity: 1;
                      @include svg-color($data-grid-header-hovered-actions-icon-color);
                    }

                  }
                }

                .data-grid-header-sort-icon-button {
                  @include transition((opacity, color, background-color));
                  @include svg-color($data-grid-header-sort-icon-color);
                  opacity: 0;
                  border-radius: 50%;
                  background-color: $data-grid-header-sort-icon-background-color;
                  padding: 0;
                  min-width: $icon-size;
                  width: $icon-size;
                  height: $icon-size;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    @include transition(all);
                  }

                  &.active {
                    opacity: 1;

                    &.descending {
                      svg {
                        transform: rotate(180deg);
                      }
                    }
                  }

                  &:hover {
                    background-color: $data-grid-header-hovered-sort-icon-background-color;
                    @include svg-color($data-grid-header-hovered-sort-icon-color);
                  }
                }
              }

              .data-grid-column-resizer {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                height: 25px;
                width: 2px;
                background-color: $data-grid-header-cell-resizer-color;
                @include transition((width, background-color));
                cursor: col-resize;

                &.reverse {
                  left: 0;
                }

                &:not(.reverse) {
                  right: 0;
                }

                &:hover,
                &.resizing {
                  width: 3px;
                  background-color: $data-grid-header-cel-resizer-resizing-color;
                }
              }

              &:hover {
                .data-grid-header-sort-icon-button {
                  opacity: 0.6;
                }

                .data-grid-header-actions-icon-button-container {
                  .data-grid-header-actions-icon-button {
                    opacity: 0.8;
                  }
                }
              }
            }

            &.center {
              width: calc(var(--width));

              .data-grid-header-inner-cell-content {
                justify-content: center;
                padding: 0 $content-padding-size;
                width: 100%;

                .data-grid-header-title {
                  max-width: calc(calc(var(--width)) - #{$icon-widths});
                }

                .data-grid-header-sort-icon-button {
                  margin-left: 0.25rem;
                }

                .data-grid-header-actions-icon-button-container {
                  flex-grow: unset;
                  justify-content: flex-start;

                  .data-grid-header-actions-icon-button {
                    margin-left: 0.25rem;
                  }
                }
              }
            }

            &.left {
              width: calc(var(--width));

              .data-grid-header-inner-cell-content {
                justify-content: flex-start;
                padding: 0 0 0 $content-padding-size;

                .data-grid-header-title {
                  max-width: calc(calc(var(--width)) - #{$icon-widths});
                }

                .data-grid-header-sort-icon-button {
                  margin-left: 0.25rem;
                }

                .data-grid-header-actions-icon-button {
                  margin-right: 0.25rem;
                }
              }
            }

            &.right {
              width: calc(var(--width));

              .data-grid-header-inner-cell-content {
                justify-content: flex-start;
                flex-direction: row-reverse;
                padding: 0 $content-padding-size 0 0;

                .data-grid-header-sort-icon-button {
                  margin-right: 0.25rem;
                }

                .data-grid-header-title {
                  max-width: calc(calc(var(--width)) - #{$icon-widths});
                }

                .data-grid-header-actions-icon-button-container {
                  justify-content: flex-start;

                  .data-grid-header-actions-icon-button {
                    margin-left: 0.25rem;
                  }
                }
              }
            }

            &.reorderable {
              cursor: grab;
            }

            &.sortable-ghost {
              background-color: $data-grid-header-cell-dragged-ghost-background-color;
              border-radius: $data-grid-header-border-radius;
              cursor: copy;

              .data-grid-icon-button {
                opacity: 0 !important;
              }
            }

            &.pinned {
              .data-grid-header-inner-cell {
                .data-grid-header-inner-cell-content {
                  .data-grid-header-title {
                    color: $data-grid-pinned-header-title-color;
                  }
                }
              }
            }

            &.left-pinned {
              z-index: 3;
              position: sticky;
              left: calc(var(--offset));
            }

            &.right-pinned {
              z-index: 2;
              position: sticky;
              right: calc(var(--offset));
            }
          }

          &.dragging-cell {
            cursor: move;

            .data-grid-header-cell {
              cursor: not-allowed;

              &.reorderable {
                cursor: copy;
              }

            }
          }

          &.resizing-cell {
            cursor: col-resize;

            * {
              cursor: col-resize;

              * {
                cursor: col-resize;
              }
            }
          }
        }

        .data-grid-body {
          $content-padding-size: 10px;

          .data-grid-body-row {

            .data-grid-row-body-cell {
              $icon-widths: 24px + 28px;
              min-width: fit-content;
              padding: 0.5rem 0;
              z-index: 1;
              background-color: $data-grid-cell-background-color;
              border-bottom: 1px solid $data-grid-cell-border-color;

              .data-grid-row-body-inner-cell {
                @include rfs($text-sm);
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                color: $data-grid-cell-color;

                .data-grid-row-body-inner-cell-text {
                  overflow-x: hidden;
                  max-width: 100%;
                  text-overflow: ellipsis;
                  cursor: text;
                }

                &.data-grid-row-body-inner-cell-custom-element {
                  p {
                    cursor: text;
                  }
                }

                .data-grid-child-toggler-button {

                  svg {
                    @include transition(transform);
                  }

                  &.open {
                    svg {
                      transform: rotate(-180deg);
                    }
                  }

                  &:hover {
                    background-color: transparent;
                  }
                }

                .data-grid-group-toggler-button {
                  margin-right: 0.4rem;
                  padding: 0.1rem;
                  @include svg-color($data-grid-row-group-toggler-color);

                  svg {
                    @include transition(transform);
                  }

                  &.invisible {
                    visibility: hidden;
                    margin-right: 1.4rem;
                  }

                  &.half-margin {
                    margin-right: 0.4rem;
                  }

                  &.open {
                    svg {
                      transform: rotate(90deg);
                    }
                  }

                  &:hover {
                    border-radius: 50% !important;
                    @include svg-color($data-grid-theme-primary-color);
                  }
                }

                &.compact {
                  padding-top: 0;
                  padding-bottom: 0;
                }

                &.standard {
                  padding-top: 5px;
                  padding-bottom: 5px;
                }

                &.relaxed {
                  padding-top: 10px;
                  padding-bottom: 10px;
                }
              }

              &.center {
                width: calc(var(--width));

                .data-grid-row-body-inner-cell {
                  justify-content: center;
                  padding-right: $content-padding-size;
                  padding-left: $content-padding-size;
                  width: 100%;
                  text-align: center;
                }
              }

              &.left {
                width: calc(var(--width));

                .data-grid-row-body-inner-cell {
                  justify-content: flex-start;
                  padding-left: $content-padding-size;
                  text-align: left;
                }
              }

              &.right {
                width: calc(var(--width));

                .data-grid-row-body-inner-cell {
                  justify-content: flex-end;
                  padding-right: $content-padding-size;
                  text-align: right;
                }
              }

              &.left-pinned {
                z-index: 2;
                position: sticky;
                left: calc(var(--offset));

                &.last-left-pinned {

                  box-shadow: 2px 4px 10px 1px $data-grid-last-left-pinned-box-shadow-color;
                  clip-path: inset(0px -15px 0px 0px);
                }
              }

              &.right-pinned {
                z-index: 1;
                position: sticky;
                right: calc(var(--offset));

                &.first-right-pinned {
                  box-shadow: -2px 4px 10px 1px $data-grid-first-right-pinned-box-shadow-color;
                  clip-path: inset(0px 0px 0px -15px);
                }
              }

              &.detailed-panel-visible {
                border-bottom: none;
              }

              &.editable {
                cursor: pointer;
              }

              &:first-of-type {
                border-left: 1px solid $data-grid-cell-border-color;
              }

              &:last-of-type {
                border-right: 1px solid $data-grid-cell-border-color;
              }

              &.secondary {
                .data-grid-row-body-inner-cell {
                  @include font-family($data-grid-secondary-font-family);
                }
              }
            }

            &.data-grid-grouped-row {
              .data-grid-row-body-cell {
                background-color: $data-grid-grouped-row-cell-background-color;
              }
            }

            .data-grid-checkbox {
              &.show-on-hover:not(.force-show) {
                visibility: hidden;
              }
            }

            &:hover {
              .data-grid-row-body-cell {
                background-color: $data-grid-hovered-cell-background-color;
              }

              .data-grid-checkbox {
                &.show-on-hover {
                  visibility: visible;
                }
              }
            }

            &.selected {
              .data-grid-row-body-cell {
                background-color: $data-grid-selected-cell-background-color;
              }
            }

            &:last-of-type {
              td {
                &:first-of-type {
                  border-bottom-left-radius: 5px;
                }

                &:last-of-type {
                  border-bottom-right-radius: 5px;
                }
              }
            }
          }


          .data-grid-detailed-panel-container {
            &:not(.visible) {
              td {
                height: 0;
                padding: 0;
                border: none;
              }
            }

            &.visible {
              > td {
                border-bottom: 1px solid $data-grid-cell-border-color;

                &:first-of-type {
                  border-left: 1px solid $data-grid-cell-border-color;
                }

                &:last-of-type {
                  border-right: 1px solid $data-grid-cell-border-color;
                }
              }
            }

            .data-grid-detailed-panel {
              padding: 3rem;
              box-shadow: inset 0 1px 4px $data-grid-detailed-panel-box-shadow;
              background-color: $data-grid-detailed-panel-background-color;
              width: 100%;
              overflow-x: auto;
            }
          }

        }

        &:not(.scrolling) {
          .data-grid-row-body-cell {
            &:not(.pinned) {
              cursor: grab;
            }
          }
        }

        &.scrolling {
          .data-grid-row-body-cell {
            &:not(.pinned) {
              cursor: grabbing;

              .data-grid-row-body-inner-cell {
                .data-grid-row-body-inner-cell-text {
                  user-select: none;
                }

                &.data-grid-row-body-inner-cell-custom-element {
                  user-select: none;
                }
              }
            }
          }
        }
      }
    }

    .data-grid-footer {
      width: 100%;
      overflow-x: auto;
      display: flex;
      align-items: center;
      padding-top: 1.5rem;
      padding-bottom: 1rem;

      .data-grid-footer-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .data-grid-footer-actions-selection-text {
          margin-left: 1rem;
          @include rfs($text-sm);
          color: $data-grid-footer-actions-selection-text-color;
          font-weight: 600;
          white-space: nowrap;
        }

        .data-grid-footer-actions-button {
          @include svg-color($data-grid-footer-action-color);
          color: $data-grid-footer-action-color;
          font-weight: 600;
          border-radius: $data-grid-footer-actions-button-border-radius;
          padding: 0.25rem 0.75rem;
          margin-right: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          P {
            margin-right: 0.25rem;
          }

          &:hover {
            @include svg-color($data-grid-footer-active-action-color);
            color: $data-grid-footer-active-action-color;
          }

          &:disabled {
            @include svg-color($data-grid-toolbar-disabled-action-color);
            color: $data-grid-toolbar-disabled-action-color;
          }
        }

      }

      .data-grid-footer-pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        .data-grid-footer-pagination-text {
          @include rfs($text-xs);
          color: $data-grid-pagination-text-color;
          font-weight: 600;
          white-space: nowrap;
        }

        .data-grid-footer-pagination-page-size-dropdown {
          @include svg-color($data-grid-pagination-icon-color);
          display: flex;
          align-items: center;
          margin: 0 1.5rem;

          &:hover {
            cursor: pointer;
            @include svg-color($data-grid-pagination-hovered-icon-color);
          }

          &.disabled {
            cursor: not-allowed;
            @include svg-color($data-grid-pagination-disabled-icon-color);

            &:hover {
              @include svg-color($data-grid-pagination-hovered-disabled-icon-color);
            }
          }

        }

        .data-grid-footer-pagination-icon-button {
          @include svg-color($data-grid-pagination-icon-color);
          padding: 0;

          &.prev {
            margin: 0 0.5rem 0 1.5rem;
          }

          &.next {
            margin: 0 1rem 0 0.5rem;
          }

          &:hover {
            @include svg-color($data-grid-pagination-hovered-icon-color);
          }

          &:disabled {
            background-color: transparent;
            @include svg-color($data-grid-pagination-disabled-icon-color);


            &:hover {
              background-color: transparent;
              @include svg-color($data-grid-pagination-hovered-disabled-icon-color);
            }
          }
        }

      }
    }

    .data-grid-loading-container {
      min-height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      pointer-events: none;
      background-color: $data-grid-loading-overlay-background-color;
      border-radius: 0 0 $data-grid-loading-container-border-radius $data-grid-loading-container-border-radius;
    }

    .data-grid-empty-content-container {
      min-height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      pointer-events: none;
      border-radius: 0 0 $data-grid-empty-content-container-border-radius $data-grid-empty-content-container-border-radius;

      .default-data-grid-empty-content {
        @include rfs($text-sm);
        color: $data-grid-cell-color;
      }
    }

    &.no-toolbar {

      .data-grid-layout {
        margin-top: 2rem;
      }

      .data-grid-loading-container {
        margin-top: 2rem;
      }

      .data-grid-empty-content-container {
        margin-top: 2rem;
      }
    }

    &.data-grid-loading {
      .data-grid-layout {
        .data-grid-table {
          thead {
            tr {
              th {
                pointer-events: none;

                * {
                  pointer-events: none;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                pointer-events: none;

                * {
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
    }

  }
}

// Popovers
html {

  .data-grid-dragged-header-cell {
    @include font-family($data-grid-primary-font-family);
    cursor: copy !important;
    background-color: $data-grid-header-cell-dragged-background-color;
    border-radius: $data-grid-dragged-header-cell-border-radius;

    .data-grid-icon-button {
      opacity: 0 !important;
    }
  }

  .data-grid-popover {
    @include font-family($data-grid-primary-font-family);
    margin-top: 0.25rem;

    //* {
    //  @include custom-scrollbar();
    //}

    .data-grid-popover-paper {
      background-color: $data-grid-popover-background-color;
      box-shadow: 0 2px 5px $data-grid-popover-shadow-color;
      padding: 0.5rem 1rem;

      .popover-item {
        @include rfs($text-sm);
        border-radius: $data-grid-toolbar-action-item-border-radius;
        margin: 0 -0.75rem;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;

        p {
          margin-left: 0.5rem;
        }

        &.default {
          color: $data-grid-popover-default-item-color;
          background-color: $data-grid-popover-default-item-background-color;
        }

        &.delete {
          color: $data-grid-popover-delete-item-color;
          background-color: $data-grid-popover-delete-item-background-color;
        }

        &.active {
          color: $data-grid-popover-active-hovered-item-color;
          background-color: $data-grid-popover-active-hovered-item-background-color;
        }

        &.disabled {
          color: $data-grid-popover-disabled-item-color;
          background-color: $data-grid-popover-disabled-item-background-color;
        }

        &:hover {
          &.default {
            color: $data-grid-popover-default-hovered-item-color;
            background-color: $data-grid-popover-default-hovered-item-background-color;
          }

          &.delete {
            color: $data-grid-popover-delete-hovered-item-color;
            background-color: $data-grid-popover-delete-hovered-item-background-color;
          }

          &.active {
            color: $data-grid-popover-active-hovered-item-color;
            background-color: $data-grid-popover-active-hovered-item-background-color;
          }

          &.disabled {
            color: $data-grid-popover-disabled-hovered-item-color;
            background-color: $data-grid-popover-disabled-hovered-item-background-color;
          }
        }
      }

    }
  }

  .data-grid-toolbar-action-popover {

    .data-grid-toolbar-action-popover-paper {

      .data-grid-toolbar-popover-header {
        margin: -0.5rem -1rem 0 -1rem;
        padding: 0.5rem 1rem;
        background-color: $data-grid-popover-header-background-color;
        display: flex;
        flex-direction: column;
      }

      .data-grid-toolbar-popover-body {
        margin: 0 -1rem -0.5rem -1rem;
        padding: 0 1rem 0 1rem;
        display: flex;
        flex-direction: column;
        max-height: 400px;
        overflow-y: auto;
        //@include custom-scrollbar();
      }

      .title {
        @include rfs($text-xs);
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      .data-grid-input {
        margin-bottom: 0.5rem;

        input {
          background-color: $white;
          border-radius: $data-grid-input-border-radius;
        }
      }

      .no-items {
        padding: 1rem;
        @include rfs($text-sm);
        color: $data-grid-popover-no-items-color;
        text-align: center;
      }

      &.bounded-width {
        max-width: 300px;
      }
    }
  }

  .data-grid-toolbar-columns-filter-action-popover {
    .popover-item {
      font-weight: 500;
      cursor: pointer;
    }
  }

  .data-grid-toolbar-density-action-popover {
    .popover-item {
      @include svg-color($data-grid-icon-color);
      cursor: pointer;

      &.active {
        cursor: not-allowed;
      }
    }
  }

  .data-grid-header-actions-popover {

    .data-grid-header-actions-popover-paper {
      .popover-item {
        font-weight: 500;
        cursor: pointer;
      }
    }

  }

  .data-grid-footer-pagination-page-sizes-action-popover {
    .popover-item {
      cursor: pointer;

      P {
        font-weight: 500;
        margin-left: 0;
      }

      &.active {
        cursor: not-allowed;
      }
    }
  }

  .data-grid-footer-actions-popover {

    .data-grid-footer-actions-popover-paper {
      .popover-item {
        cursor: pointer;
        justify-content: start;

        P {
          font-weight: 600;
          margin-left: 0;
        }

        &.disabled {
          cursor: progress;
        }
      }
    }

  }

  .data-grid-cell-editor-popover {

    .data-grid-cell-editor-popover-paper {
      padding: 1rem 2rem;

      .data-grid-cell-editor {
        display: flex;
        flex-direction: column;

        .data-grid-cell-editor-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 0.5rem;

          .data-grid-button {
            border-radius: $data-grid-button-border-radius;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            min-width: fit-content;

            &.save-action {
              @include svg-color($data-grid-save-action-color);
              @include rfs($text-sm);
              color: $data-grid-save-action-color;
              background-color: $data-grid-save-action-background-color;
              font-weight: 600;
              padding: 0.25rem 0.5rem 0.25rem 0.25rem;

              &:disabled {
                @include svg-color($data-grid-disabled-save-action-color);
                color: $data-grid-disabled-save-action-color;
                background-color: $data-grid-disabled-save-action-background-color;
              }
            }

            &.cancel-action {
              @include svg-color($data-grid-cancel-action-color);
              @include rfs($text-sm);
              color: $data-grid-cancel-action-color;
              background-color: $data-grid-cancel-action-background-color;
              font-weight: 600;
              padding: 0.25rem 0.5rem 0.25rem 0.25rem;
              margin-right: 0.5rem;

              &:disabled {
                @include svg-color($data-grid-disabled-cancel-action-color);
                color: $data-grid-disabled-cancel-action-color;
                background-color: $data-grid-disabled-cancel-action-background-color;
              }
            }
          }

        }
      }

    }

  }
}

// Components
html {

  .data-grid-button {
    @include font-family($data-grid-primary-font-family);
    @include rfs($text-sm);
    @include transition(all);
    border-radius: $data-grid-button-border-radius;
    min-width: 4rem;
    padding: 0.5rem 0.5rem;
    border: 1px solid transparent;
    background-color: transparent;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .data-grid-icon-button {
    @include font-family($data-grid-primary-font-family);
    @include svg-color($data-grid-icon-color);
    min-width: 1.25rem;
    min-height: 1.25rem;
    padding: 0.5rem;
    border-radius: $data-grid-button-border-radius;
    background-color: $data-grid-icon-background-color;
    border: none;

    &:hover {
      @include svg-color($data-grid-hovered-icon-color);
      background-color: $data-grid-hovered-icon-background-color;
    }

    &:disabled {
      @include svg-color($data-grid-disabled-icon-color);
      background-color: $data-grid-disabled-icon-background-color;

      &:hover {
        @include svg-color($data-grid-disabled-hovered-icon-color);
        background-color: $data-grid-disabled-hovered-icon-background-color;
      }
    }
  }

  .data-grid-button,
  .data-grid-icon-button {
    outline: none;

    &:hover {
      @include svg-color($data-grid-hovered-icon-color);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .data-grid-input {
    @include font-family($data-grid-primary-font-family);
  }

  .data-grid-switch-wrapper {
    @include font-family($data-grid-primary-font-family);
    @include transition();
    --switch_size: 5;
    $size: var(--switch_size);

    $main-disabled-color: $data-grid-disabled-switch-color;
    $off-disabled-color: $data-grid-disabled-switch-background-color;
    $main-color: $data-grid-switch-color;
    $off-color: $data-grid-switch-background-color;
    $border-color: $data-grid-switch-wrapper-border-color;

    display: flex;
    align-items: center;
    width: calc(1px * (#{$size} * 14));
    height: calc(1px * (#{$size} * 6.5));
    padding: 0 calc(3.5px * (#{$size} / 8));
    border: 1.5px solid $border-color;
    border-radius: calc(1px * (3.5 * #{$size} + ((0.1 * #{$size}) * 2)));
    position: relative;

    .switch {
      @include transition();
      position: absolute;
      margin: 0 calc(1px * (#{$size} / 10));
      cursor: pointer;
      height: calc(1px * (#{$size} * 5.5));;
      width: calc(1px * (#{$size} * 5.5));
      border-radius: 100%;
      background: $off-color;
      box-shadow: inset 0 0 5px $data-grid-switch-wrapper-box-shadow;

      &.no-transition {
        transition: all 50ms linear !important;
      }
    }

    .switch-text {
      @include transition();
      position: absolute;
      color: $data-grid-switch-text-color;
      font-size: calc(1px * (#{$size} * 2.5));
      font-weight: 600;
      opacity: 0;
      padding-inline: 0.25rem;

      &.switch-text-start {
        text-align: left;
      }

      &.switch-text-end {
        text-align: right;
      }

      &:hover {
        cursor: pointer;
      }

      &.show {
        opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      border-color: $main-disabled-color;
      cursor: not-allowed;

      .switch {
        background: $main-disabled-color;
        box-shadow: inset 0 0 5px $off-disabled-color;
        cursor: not-allowed;
      }

      .switch-text {
        cursor: not-allowed;
        color: $data-grid-disabled-switch-text-color;
      }

      &:hover {
        cursor: not-allowed;
      }
    }

    &.checked {
      border-color: $main-color;

      .switch {
        background: $main-color;
        box-shadow: inset 0 0 5px $off-color;
      }
    }
  }

}

// scrollbar
html {
  body {
    &.force-webkit-scrollbar {
      .data-grid-container {
        * {
          @include custom-scrollbar(true);
        }
      }

      .data-grid-popover {
        * {
          @include custom-scrollbar(true);
        }
      }

      .data-grid-toolbar-action-popover {
        .data-grid-toolbar-action-popover-paper {
          .data-grid-toolbar-popover-body {
            @include custom-scrollbar(true);
          }
        }
      }
    }

    &:not(.force-webkit-scrollbar) {
      .data-grid-container {
        * {
          @include custom-scrollbar(false);
        }
      }

      .data-grid-popover {
        * {
          @include custom-scrollbar(false);
        }
      }

      .data-grid-toolbar-action-popover {
        .data-grid-toolbar-action-popover-paper {
          .data-grid-toolbar-popover-body {
            @include custom-scrollbar(false);
          }
        }
      }

    }
  }
}
