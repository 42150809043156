@import "keyframes";
@import "../functions/theme-var";

@include keyframes(shimmer) {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@mixin loading-shimmer($width: 400px, $height: 200px, $duration: 1s, ) {
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: theme-var(palette-app-specific-loading-shimmer);
    background-image: theme-var(palette-app-specific-loading-shimmer-gradient);
    background-repeat: no-repeat;
    background-size: $width $height;
    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    border-radius: inherit;
  }
}
