@use '../../../assets/css/index' as css;

html {


  .save-rollback {

    .check-icon {
      .bg {
        @include css.transition();
        fill: css.theme-var(palette-primary-main);
        fill-opacity: 0.2;
        opacity: 0.4;
      }

      .fg {
        @include css.transition();
        fill: css.theme-var(palette-primary-main);
      }
    }

    .rollback-icon {
      .bg {
        @include css.transition();
        fill: css.theme-var(palette-error-main);
        fill-opacity: 0.2;
        opacity: 0.4;
      }

      .fg {
        @include css.transition(0.2s);
        fill: css.theme-var(palette-error-main);
      }
    }
  }

}
