@use '../../../@bizkeytech/base/assets/css' as css;

html {

  @include css.keyframes(loading-circles-rotation) {
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-circles-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .loading-circles-container {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 400px;
    height: 400px;

    .loading-circle {
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      background: transparent;

      $nth-of-type-list: (
                      (1, 200px, 180px, 6s, 500),
                      (2, 180px, 200px, 10s, 100),
                      (3, 210px, 190px, 5s, 700),
                      (4, 190px, 210px, 15s, 300),
                      (5, 230px, 190px, 6s, 400),
                      (6, 190px, 230px, 10s, 500),
                      (7, 240px, 220px, 5s, 600),
                      (8, 220px, 240px, 15s, 200),
                      (9, 250px, 230px, 12s, 800),
                      (10, 230px, 250px, 6s, 700)
      );

      @each $index, $width, $height, $time, $palette in $nth-of-type-list {
        &:nth-of-type(#{$index}) {
          width: $width;
          height: $height;
          animation: loading-circles-rotation $time infinite linear;
          box-shadow: 0 0 1px 0 css.theme-var(palette-primary-#{$palette}),
          inset 0 0 10px 0 css.theme-var(palette-primary-#{$palette});
        }
      }

    }
  }

  .loading-circles-text {
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.5em;
    max-width: css.css-min(90vw, 550px);

    span {
      display: inline-block;
    }

    .reveal-dots {
      opacity: 0;
    }
  }

}
