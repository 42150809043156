@use "../../base/assets/css/index" as css;

html {

  .mapbox {
    position: relative;

    .map-container {
      border-radius: css.theme-var(shape-borderRadius);

      .mapboxgl-ctrl-bottom-right {
        display: none;
      }

      .mapbox-marker {
        cursor: pointer;

        svg {
          @include css.transition();

          circle {
            fill: css.theme-var(palette-background-default) !important;
          }

          path {
            fill: css.theme-var(palette-success-main) !important;
          }
        }

        &:hover {
          svg {
            transform: translateY(-5px) scale(1.2);

            > g > g {
              fill: css.theme-var(palette-primary-main) !important;
            }
          }
        }
      }
    }

    .marker {
      transform: translateY(-15px);
      cursor: pointer;
      position: absolute;
      inset: 0;
      border: none;
      margin: auto;
      outline: 0;
      background-color: transparent;
      width: 48px;
      height: 48px;
    }

    canvas {
      border-radius: css.theme-var(shape-borderRadius);
    }

  }

}
