@use "../../@bizkeytech/base/assets/css" as css;


html {
  .application-view {
    min-height: 100vh;
    background-color: css.theme-var(palette-background-default);

    .app {
      &.centered {
        width: 100vw;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.error-cursor {
        cursor: none;
      }

      .loading-bar-container {
        $color: css.theme-var(palette-primary-main);

        .loading-bar {
          background: $color !important;
          color: $color !important;

          > div {
            box-shadow: $color 0 0 10px, $color 0 0 5px;;
          }
        }
      }
    }
  }
}
