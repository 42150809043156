@use "./@bizkeytech/base/assets/css" as css;

html {
  @include css.font-family("Inter");

  body {
    &.force-webkit-scrollbar {
      * {
        @include css.custom-scrollbar(true);
      }
    }

    &:not(.force-webkit-scrollbar) {
      @include css.custom-scrollbar(false);
    }
  }


  a {
    text-decoration: none;
  }
}
