@use '../../../../assets/css/index' as css;


// CK editor styling
html {
  --ck-color-base-foreground: #fafafa;
  --ck-color-base-background: #{css.theme-var(palette-background-paper)} !important;
  --ck-color-base-border: rgba(#{css.theme-var(palette-common-onBackgroundChannel)} / 0.23) !important;
  --ck-color-base-action: #61b045;
  --ck-color-base-focus: #6cb5f9;
  --ck-color-base-text: #{css.theme-var(palette-text-primary)} !important;
  --ck-color-base-active: green !important;
  --ck-color-base-active-focus: #0e7fe1;
  --ck-color-base-error: #db3700;
  --ck-color-focus-border-coordinates: 208, 79%, 51%;
  --ck-color-focus-border: rgba(#{css.theme-var(palette-primary-mainChannel)} / 0.8) !important;
  --ck-color-focus-outer-shadow: #{css.theme-var(palette-primary-main)} !important;
  --ck-color-focus-disabled-shadow: rgba(119, 186, 248, 0.3);
  --ck-color-focus-error-shadow: rgba(255, 64, 31, 0.3);
  --ck-color-text: #{css.theme-var(palette-text-primary)} !important;
  --ck-color-shadow-drop: rgba(0, 0, 0, 0.15);
  --ck-color-shadow-drop-active: rgba(0, 0, 0, 0.2);
  --ck-color-shadow-inner: rgba(0, 0, 0, 0.1);
  --ck-color-button-default-background: #{css.theme-var(palette-background-paper)} !important;
  --ck-color-button-default-hover-background: #{css.theme-var(palette-primary-light)} !important;
  --ck-color-button-default-active-background: #{css.theme-var(palette-primary-light)} !important;
  --ck-color-button-default-active-shadow: #bfbfbf;
  --ck-color-button-default-disabled-background: transparent;
  --ck-color-button-on-background: #{css.theme-var(palette-primary-main)} !important;
  --ck-color-button-on-hover-background: #{css.theme-var(palette-primary-main)} !important;
  --ck-color-button-on-active-background: #bababa;
  --ck-color-button-on-active-shadow: #a1a1a1;
  --ck-color-button-on-disabled-background: #dedede;
  --ck-color-button-action-background: var(--ck-color-base-action);
  --ck-color-button-action-hover-background: #579e3d;
  --ck-color-button-action-active-background: #53973b;
  --ck-color-button-action-active-shadow: #498433;
  --ck-color-button-action-disabled-background: #7ec365;
  --ck-color-button-action-text: var(--ck-color-base-background);
  --ck-color-button-save: #008a00;
  --ck-color-button-cancel: #db3700;
  --ck-color-switch-button-off-background: #b0b0b0;
  --ck-color-switch-button-off-hover-background: #a3a3a3;
  --ck-color-switch-button-on-background: var(--ck-color-button-action-background);
  --ck-color-switch-button-on-hover-background: #579e3d;
  --ck-color-switch-button-inner-background: var(--ck-color-base-background);
  --ck-color-switch-button-inner-shadow: rgba(0, 0, 0, 0.1);
  --ck-color-dropdown-panel-background: var(--ck-color-base-background);
  --ck-color-dropdown-panel-border: var(--ck-color-base-border);
  --ck-color-input-background: var(--ck-color-base-background);
  --ck-color-input-border: #c7c7c7;
  --ck-color-input-error-border: var(--ck-color-base-error);
  --ck-color-input-text: var(--ck-color-base-text);
  --ck-color-input-disabled-background: #f2f2f2;
  --ck-color-input-disabled-border: #c7c7c7;
  --ck-color-input-disabled-text: #757575;
  --ck-color-list-background: var(--ck-color-base-background);
  --ck-color-list-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-list-button-on-background: rgba(#{css.theme-var(palette-primary-mainChannel)} / 1) !important;
  --ck-color-list-button-on-background-focus: #{css.theme-var(palette-primary-light)} !important;
  --ck-color-list-button-on-text: var(--ck-color-base-background);
  --ck-color-panel-background: var(--ck-color-base-background);
  --ck-color-panel-border: var(--ck-color-base-border);
  --ck-color-toolbar-background: #{css.theme-var(palette-background-default)} !important;
  --ck-color-toolbar-border: rgba(#{css.theme-var(palette-common-onBackgroundChannel)} / 0.23) !important;
  --ck-color-tooltip-background: rgba(#{css.theme-var(palette-common-onBackgroundChannel)} / 0.23) !important;
  --ck-color-engine-placeholder-text: #707070;
  --ck-color-upload-bar-background: #6cb5f9;
  --ck-color-link-default: #{css.theme-var(palette-primary-main)} !important;
  --ck-color-link-selected-background: rgba(31, 176, 255, 0.1);
  --ck-color-link-fake-selection: rgba(31, 176, 255, 0.3);
  --ck-disabled-opacity: 0.5;
  --ck-focus-outer-shadow-geometry: 0 0 0 2px !important;
  --ck-focus-outer-shadow: #{css.theme-var(palette-primary-main)} !important;
  --ck-focus-disabled-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-disabled-shadow);
  --ck-focus-error-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-error-shadow);
  --ck-focus-ring: 1px solid #{css.theme-var(palette-primary-main)};
  --ck-font-size-base: 13px;
  --ck-line-height-base: 1.84615;
  --ck-font-face: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  --ck-font-size-tiny: 0.7em;
  --ck-font-size-small: 0.75em;
  --ck-font-size-normal: 1em;
  --ck-font-size-big: 1.4em;
  --ck-font-size-large: 1.8em;
  --ck-ui-component-min-height: 2.3em;

  .ck.ck-media-form {
    background-color: #{css.theme-var(palette-background-default)} !important;
  }

  .ck.ck-dropdown__panel {
    background-color: #{css.theme-var(palette-background-default)} !important;
  }

  .ck.ck-balloon-panel_with-arrow {
    background-color: #{css.theme-var(palette-background-paper)};

    &:after {
      border-style: unset !important;
    }

    &:before {
      display: none !important;
    }
  }

  .ck.ck-balloon-rotator {
    &:after {
      display: none !important;
    }
  }

  .ck.ck-toolbar {
    border-radius: #{css.theme-var(shape-borderRadius)} #{css.theme-var(shape-borderRadius)} 0 0 !important;
  }

  .ck.ck-content {
    border-radius: 0 0 #{css.theme-var(shape-borderRadius)} #{css.theme-var(shape-borderRadius)} !important;
    min-height: 250px;
  }

  .ck-editor {
    .table {
      color: #{css.theme-var(palette-text-primary)};
    }
  }

  .error {
    .ck.ck-content, .ck.ck-toolbar {
      border-color: #{css.theme-var(palette-error-main)} !important;
    }
  }
}
