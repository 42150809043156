// **************** TEXT SIZES ****************

$text-xxs: 10px !default;
$text-xs: 12px !default;
$text-s: 13px !default;
$text-sm: 14px !default;
$text-md: 16px !default;
$text-lg: 18px !default;
$text-xl: 20px !default;
$text-title: 26px !default;
$text-xxl: 30px !default;

// **************** DATA GRID SIZES ****************


$data-grid-shape-border-radius: 10px !default;

$data-grid-button-border-radius: $data-grid-shape-border-radius !default;
$data-grid-input-border-radius: $data-grid-shape-border-radius !default;
$data-grid-toolbar-action-item-border-radius: 3px !default;

$data-grid-toolbar-button-border-radius: $data-grid-shape-border-radius !default;
$data-grid-container-border-radius: $data-grid-shape-border-radius !default;
$data-grid-loading-container-border-radius: $data-grid-shape-border-radius !default;
$data-grid-empty-content-container-border-radius: $data-grid-shape-border-radius !default;
$data-grid-layout-border-radius: $data-grid-shape-border-radius !default;
$data-grid-table-border-radius: $data-grid-shape-border-radius !default;
$data-grid-header-border-radius: $data-grid-shape-border-radius !default;
$data-grid-footer-actions-button-border-radius: $data-grid-shape-border-radius !default;
$data-grid-dragged-header-cell-border-radius: $data-grid-shape-border-radius !default;
