@use '../../../assets/css/index' as css;

html {
  .video-container-layout {
    position: relative;
    @include css.transition();

    &.active:not(&.large) {
      margin-bottom: 26px;
    }

    .video-container {
      position: relative;
      line-height: 0;

      iframe, video {
        width: 100%;
        cursor: pointer;
      }

      .play-button-container {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 1;

        .play-button {
          $size: 4rem;
          cursor: pointer;

          @include css.transition();
          width: $size;
          height: $size;
          border: none;
          background-color: css.theme-var(palette-app-specific-video-player-button-background-color);
          border-radius: 100%;

          svg {
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            height: 50%;
            width: 50%;

            path {
              fill: css.theme-var(palette-app-specific-video-player-button-color);
            }
          }

          &:hover {
            $size: 5rem;

            background-color: css.theme-var(palette-app-specific-video-player-button-hover-background-color);
            width: $size;
            height: $size;

            svg {
              path {
                fill: css.theme-var(palette-app-specific-video-player-button-hover-color);
              }
            }
          }
        }
      }
    }

    .video-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.small {
        position: absolute;
        bottom: -25px;

        .inner {
          width: 95%;
        }

      }

      &.large {
        .inner {
          width: 100%;
          position: absolute;
          bottom: 0;
          padding-bottom: 5px;

          .progress {
            margin: 0 10px;
          }

          .video-icon {
            width: 36px;
            height: 30px;
            cursor: pointer;
            margin: 5px 7px;
            @include css.transition();
            background-color: css.theme-var(palette-app-specific-video-player-button-background-color);
            border-radius: 100%;
            padding: 4px;

            path {
              @include css.transition();
              fill: css.theme-var(palette-app-specific-video-player-button-color);
            }

            &:hover {
              transform: scale(1.2);
              background-color: css.theme-var(palette-app-specific-video-player-button-hover-background-color);

              path {
                fill: css.theme-var(palette-app-specific-video-player-button-hover-color);
              }
            }
          }
        }
      }

      .inner {
        border-radius: 7px;
        padding: 12px 5px 2px 5px;
        backdrop-filter: blur(9px);
        -webkit-backdrop-filter: blur(9px);
        background-color: css.theme-var(palette-app-specific-video-player-controls-container-background-color);
        display: flex;
        align-items: center;
        width: 100%;

        .progress {
          width: 100%;
          height: 7px;
          margin: 0 10px;
          appearance: none;
          cursor: pointer;

          &::-webkit-progress-bar {
            cursor: pointer;
            height: 7px;
            border-radius: 3px;
          }

          &::-webkit-progress-inner-element {
            height: 7px;
            border-radius: 3px;
          }

          &::-webkit-progress-value {
            height: 7px;
            border-radius: 3px;
          }

        }

        .video-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
          margin: 5px 7px;
          fill: css.theme-var(palette-app-specific-video-player-button-color);
          @include css.transition();

          &:hover {
            transform: scale(1.4);
          }
        }
      }
    }
  }

}
