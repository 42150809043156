@use "../../../assets/css" as css;


html {

  $easing: cubic-bezier(0.19, 1, 0.22, 1);
  $duration: 700ms;

  .cursor-follower-dot {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    transition: transform $duration $easing;

    &-inner {
      position: relative;
      width: 50px;
      height: 50px;
      transition: width $duration $easing, height $duration $easing;

      &-circle {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 50px;
        height: 50px;
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: css.theme-var(palette-text-primary);
        border-radius: 50%;
        transition: background-color $duration $easing, border-width $duration $easing, border-color $duration $easing, width $duration $easing, height $duration $easing;
      }

      &-dot {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 5px;
        height: 5px;
        background-color: css.theme-var(palette-text-primary);
        border-radius: 50%;
        transition: background-color $duration $easing, width $duration $easing, height $duration $easing;
      }
    }

    &.expand {
      .cursor-follower-dot-inner {
        width: 70px;
        height: 70px;

        &-circle {
          width: 70px;
          height: 70px;
          background-color: rgba(#{css.theme-var(palette-text-primary-mainChannel)} / 0.1);
          border-width: 0;
          border-color: transparent;
        }

        &-dot {
          width: 0;
          height: 0;
          background-color: transparent;
        }
      }
    }

  }

  .cursor-follower-canvas {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 0;
  }

}
