// Commons
$white: rgba(255, 255, 255, 1) !default;

// Data grid
$data-grid-container-background-color: white !default;
$data-grid-loading-overlay-background-color: rgba(0, 0, 0, 0.1) !default;
$data-grid-theme-primary-color: #E86741 !default;


// Icon buttons
$data-grid-icon-color: #3E6E9C !default;
$data-grid-icon-background-color: transparent !default;
$data-grid-hovered-icon-color: #3E6E9C !default;
$data-grid-hovered-icon-background-color: #F5F7F9 !default;

$data-grid-disabled-icon-color: rgba(179, 179, 179, 1) !default;
$data-grid-disabled-icon-background-color: rgba(243, 243, 243, 1) !default;
$data-grid-disabled-hovered-icon-color: rgba(179, 179, 179, 1) !default;
$data-grid-disabled-hovered-icon-background-color: rgba(243, 243, 243, 1) !default;


// Footer Actions
$data-grid-footer-actions-selection-text-color: rgba(0, 0, 0, 0.54) !default;
$data-grid-footer-action-color: rgba(62, 110, 156, 1) !default;
$data-grid-footer-disabled-action-color: $data-grid-disabled-icon-color !default;
$data-grid-footer-active-action-color: rgba(62, 110, 156, 0.7) !default;


// Pagination
$data-grid-pagination-text-color: rgba(0, 0, 0, 0.54) !default;
$data-grid-pagination-icon-color: #3E6E9C !default;
$data-grid-pagination-hovered-icon-color: rgba(#3E6E9C, 0.7) !default;
$data-grid-pagination-disabled-icon-color: rgba(0, 0, 0, 0.54) !default;
$data-grid-pagination-hovered-disabled-icon-color: rgba(0, 0, 0, 0.34) !default;

// Toolbar action buttons
$data-grid-toolbar-action-color: rgba(62, 110, 156, 1) !default;
$data-grid-toolbar-action-background-color: transparent !default;
$data-grid-toolbar-action-border-color: rgba(62, 110, 156, 0.42) !default;
$data-grid-toolbar-active-action-color: white !default;
$data-grid-toolbar-active-action-background-color: rgba(62, 110, 156, 1) !default;
$data-grid-toolbar-active-action-border-color: rgba(62, 110, 156, 1) !default;
$data-grid-toolbar-disabled-action-color: $data-grid-disabled-icon-color !default;
$data-grid-toolbar-disabled-action-background-color: $data-grid-disabled-icon-background-color !default;
$data-grid-toolbar-disabled-action-border-color: $data-grid-disabled-icon-color !default;

// Header
$data-grid-header-background-color: #F5F7F9 !default;
$data-grid-header-title-color: #726863 !default;
$data-grid-pinned-header-title-color: black !default;

// Header Cell
$data-grid-header-cell-dragged-background-color: rgba(255, 255, 255, 0.7) !default;
$data-grid-header-cell-dragged-ghost-background-color: rgba(62, 110, 156, 0.05) !default;
$data-grid-header-cell-resizer-color: rgba(62, 110, 156, 0.3) !default;
$data-grid-header-cel-resizer-resizing-color: #3E6E9C !default;

// Header Sort Icon
$data-grid-header-sort-icon-color: $data-grid-icon-color !default;
$data-grid-header-sort-icon-background-color: $data-grid-icon-background-color !default;
$data-grid-header-hovered-sort-icon-color: $data-grid-hovered-icon-color !default;
$data-grid-header-hovered-sort-icon-background-color: white !default;

// Header Actions Icon
$data-grid-header-actions-icon-color: $data-grid-icon-color !default;
$data-grid-header-actions-icon-background-color: $data-grid-icon-background-color !default;
$data-grid-header-hovered-actions-icon-color: $data-grid-hovered-icon-color !default;
$data-grid-header-hovered-actions-icon-background-color: white !default;

// Popover
$data-grid-popover-background-color: white !default;
$data-grid-popover-shadow-color: rgba(0, 0, 0, 0.07) !default;
$data-grid-popover-no-items-color: rgba(51, 51, 51, 0.7) !default;
$data-grid-popover-header-background-color: #F7F7F7 !default;


// Popover Item
$data-grid-popover-default-item-color: rgba(51, 51, 51, 1) !default;
$data-grid-popover-default-item-background-color: transparent !default;
$data-grid-popover-default-hovered-item-color: rgba(51, 51, 51, 1) !default;
$data-grid-popover-default-hovered-item-background-color: #F5F7F9 !default;

$data-grid-popover-delete-item-color: rgba(235, 87, 87, 1) !default;
$data-grid-popover-delete-item-background-color: transparent !default;
$data-grid-popover-delete-hovered-item-color: rgba(235, 87, 87, 1) !default;
$data-grid-popover-delete-hovered-item-background-color: rgba(235, 87, 87, 0.1) !default;

$data-grid-popover-active-item-color: rgba(51, 51, 51, 1) !default;
$data-grid-popover-active-item-background-color: rgba(62, 110, 156, 0.15) !default;
$data-grid-popover-active-hovered-item-color: rgba(51, 51, 51, 1) !default;
$data-grid-popover-active-hovered-item-background-color: rgba(62, 110, 156, 0.15) !default;

$data-grid-popover-disabled-item-color: rgba(179, 179, 179, 1) !default;
$data-grid-popover-disabled-item-background-color: rgba(243, 243, 243, 1) !default;
$data-grid-popover-disabled-hovered-item-color: rgba(179, 179, 179, 1) !default;
$data-grid-popover-disabled-hovered-item-background-color: rgba(243, 243, 243, 1) !default;


// Scrollbar
$data-grid-scrollbar-track-color: #F5F7F9 !default;
$data-grid-scrollbar-thumb-color: rgba(62, 110, 156, 1) !default;

// Switch
$data-grid-switch-text-color: rgba(80, 80, 80, 0.5) !default;
$data-grid-switch-color: rgba(62, 110, 156, 1) !default;
$data-grid-switch-background-color: rgba(255, 255, 255, 1) !default;
$data-grid-disabled-switch-text-color: rgba(80, 80, 80, 0.2) !default;
$data-grid-disabled-switch-color: rgba(116, 104, 98, 1) !default;
$data-grid-disabled-switch-background-color: rgba(255, 255, 255, 1) !default;
$data-grid-switch-wrapper-border-color: rgba(82, 136, 186, 1) !default;


// Cell
$data-grid-cell-color: rgba(0, 0, 0, 0.86) !default;
$data-grid-cell-background-color: white !default;
$data-grid-cell-border-color: #F8F8F8 !default;
$data-grid-selected-cell-background-color: #d6e3ef !default;
$data-grid-hovered-cell-background-color: #f2f3f9 !default;
$data-grid-grouped-row-cell-background-color: #f5f9fb !default;

// Detailed Panel
$data-grid-detailed-panel-background-color: white !default;

// Row Grouping
$data-grid-row-group-toggler-color: $data-grid-icon-color !default;
$data-grid-row-group-toggler-background-color: $data-grid-icon-background-color !default;
$data-grid-row-group-toggler-hovered-color: $data-grid-hovered-icon-color !default;
$data-grid-row-group-toggler-hovered-background-color: $data-grid-hovered-icon-background-color !default;


// Cell Editor
$data-grid-save-action-color: rgba(62, 110, 156, 1) !default;
$data-grid-save-action-background-color: rgba(62, 110, 156, 0.05) !default;
$data-grid-disabled-save-action-color: rgba(94, 94, 94, 0.5) !default;
$data-grid-disabled-save-action-background-color: rgba(0, 0, 0, 0.05) !default;

$data-grid-cancel-action-color: rgba(94, 94, 94, 1) !default;
$data-grid-cancel-action-background-color: rgba(0, 0, 0, 0.05) !default;
$data-grid-disabled-cancel-action-color: rgba(94, 94, 94, 0.5) !default;
$data-grid-disabled-cancel-action-background-color: rgba(0, 0, 0, 0.05) !default;

// Shadow Colors
$data-grid-last-left-pinned-box-shadow-color: rgba(0, 0, 0, 0.03) !default;
$data-grid-first-right-pinned-box-shadow-color: rgba(0, 0, 0, 0.05) !default;
$data-grid-detailed-panel-box-shadow: rgba(0, 0, 0, 0.3) !default;
$data-grid-switch-wrapper-box-shadow: rgba(0, 0, 0, 0.25) !default;
