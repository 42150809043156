@use '../../../assets/css' as css;

html {

  .preview-images-dialog {

    .images-preview {
      position: relative;
      width: 100vw;
      height: 100vh;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
      scroll-snap-stop: always;

      .close-button {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 10;
      }

      .next-button {
        position: fixed;
        top: 50%;
        right: 10px;
        z-index: 10;
      }

      .prev-button {
        position: fixed;
        top: 50%;
        left: 10px;
        z-index: 10;
      }

      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100vw;
        height: 100vh;

        .image {
          scroll-snap-align: center;
          width: 100vw;
          height: 100vh;
          position: relative;

          .image-prisma {
            height: calc(100vh - 90px);
            width: calc(100vw - 100px);
            display: flex;
            margin-inline: auto;
            justify-content: center;
            align-items: center;
          }

          img {
            object-fit: contain;
            border-radius: css.theme-var(shape-borderRadius);
            box-shadow: css.theme-var(shadows-1);
          }

          .controller {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

}
