@use "../../../@bizkeytech/base/assets/css" as css;

// Importing the css of the data grid with changed variables.
@use '../../../@bizkeytech/data-grid/assets/css' as data-grid with (

  // Font Styles
  $data-grid-primary-font-family: #{css.theme-var(fontFamily-body)},

  // sizes
  $data-grid-shape-border-radius: #{css.theme-var(shape-borderRadius)},

  // Colours

  // ***----- Commons -----***
  $white: #{css.theme-var(palette-background-default)},

  // ***----- Shadow Colors -----***
  $data-grid-last-left-pinned-box-shadow-color: #{css.theme-var(palette-app-specific-data-grid-last-left-pinned-box-shadow-color)},
  $data-grid-first-right-pinned-box-shadow-color: #{css.theme-var(palette-app-specific-data-grid-first-right-pinned-box-shadow-color)},
  $data-grid-detailed-panel-box-shadow: #{css.theme-var(palette-app-specific-data-grid-detailed-panel-box-shadow)},
  $data-grid-switch-wrapper-box-shadow: #{css.theme-var(palette-app-specific-data-grid-switch-wrapper-box-shadow)},

  // ***----- Data grid -----***
  $data-grid-container-background-color: #{css.theme-var(palette-background-paper)},
  $data-grid-loading-overlay-background-color: #{css.theme-var(palette-app-specific-data-grid-loading-overlay-background)},

  // ***----- Icon buttons -----***
  $data-grid-icon-color: #{css.theme-var(palette-primary-main)},
  $data-grid-icon-background-color: transparent,
  $data-grid-hovered-icon-color: #{css.theme-var(palette-primary-main)},
  $data-grid-hovered-icon-background-color: transparent,

  $data-grid-disabled-icon-color: #{css.theme-var(palette-action-disabled)},
  $data-grid-disabled-icon-background-color: #{css.theme-var(palette-action-disabledBackground)},
  $data-grid-disabled-hovered-icon-color: #{css.theme-var(palette-action-disabled)},
  $data-grid-disabled-hovered-icon-background-color: #{css.theme-var(palette-action-disabled-background)},

  // ***----- Footer Actions -----***
  $data-grid-footer-actions-selection-text-color:  #{css.theme-var(palette-text-secondary)},
  $data-grid-footer-action-color: #{css.theme-var(palette-primary-main)},
  $data-grid-footer-disabled-action-color: #{css.theme-var(palette-action-disabled)},
  $data-grid-footer-active-action-color: #{css.theme-var(palette-primary-main)},

  // ***----- Pagination -----***
  $data-grid-pagination-text-color: #{css.theme-var(palette-action-active)},
  $data-grid-pagination-icon-color: #{css.theme-var(palette-primary-main)},
  $data-grid-pagination-hovered-icon-color: rgba(#{css.theme-var(palette-primary-mainChannel)} / 0.7),
  $data-grid-pagination-disabled-icon-color: #{css.theme-var(palette-action-active)},
  $data-grid-pagination-hovered-disabled-icon-color: rgba(#{css.theme-var(palette-action-activeChannel)} / 0.3),

  // ***----- Toolbar action buttons -----***
  $data-grid-toolbar-action-color: #{css.theme-var(palette-primary-main)},
  $data-grid-toolbar-action-background-color: transparent,
  $data-grid-toolbar-action-border-color: rgba(#{css.theme-var(palette-primary-mainChannel)} /0.42),
  $data-grid-toolbar-active-action-color: #{css.theme-var(palette-background-default)},
  $data-grid-toolbar-active-action-background-color: #{css.theme-var(palette-primary-main)},
  $data-grid-toolbar-active-action-border-color: #{css.theme-var(palette-primary-main)},
  $data-grid-toolbar-disabled-action-color: #{css.theme-var(palette-action-disabled)},
  $data-grid-toolbar-disabled-action-background-color: #{css.theme-var(palette-action-disabledBackground)},
  $data-grid-toolbar-disabled-action-border-color: #{css.theme-var(palette-action-disabled)},

  // ***----- Header -----***
  $data-grid-header-background-color: #{css.theme-var(palette-background-default)},
  $data-grid-header-title-color: #{css.theme-var(palette-text-tertiary)},
  $data-grid-pinned-header-title-color: #{css.theme-var(palette-text-primary)},

  // ***----- Header Cell -----***
  $data-grid-header-cell-dragged-background-color: #{css.theme-var(palette-primary-light)},
  $data-grid-header-cell-dragged-ghost-background-color: #{css.theme-var(palette-primary-light)},
  $data-grid-header-cell-resizer-color: rgba(#{css.theme-var(palette-text-secondaryChannel)} /0.42),
  $data-grid-header-cel-resizer-resizing-color: #{css.theme-var(palette-primary-main)},

  // ***----- Header Sort Icon -----***
  $data-grid-header-sort-icon-color: #{css.theme-var(palette-primary-main)},
  $data-grid-header-sort-icon-background-color: transparent,
  $data-grid-header-hovered-sort-icon-color: #{css.theme-var(palette-primary-main)},
  $data-grid-header-hovered-sort-icon-background-color: #{css.theme-var(palette-background-default)},

  // ***----- Header Actions Icon -----***
  $data-grid-header-actions-icon-color: #{css.theme-var(palette-primary-main)},
  $data-grid-header-actions-icon-background-color: transparent,
  $data-grid-header-hovered-actions-icon-color: #{css.theme-var(palette-primary-main)},
  $data-grid-header-hovered-actions-icon-background-color: #{css.theme-var(palette-background-default)},

  // ***----- Popover -----***
  $data-grid-popover-background-color: #{css.theme-var(palette-background-paper)},
  $data-grid-popover-shadow-color: #{css.theme-var(palette-app-specific-data-grid-popover-shadow-color)},
  $data-grid-popover-no-items-color: #{css.theme-var(palette-text-disabled)},
  $data-grid-popover-header-background-color: #{css.theme-var(palette-background-default)},

  // ***----- Popover Item -----***
  $data-grid-popover-default-item-color: #{css.theme-var(palette-primary-main)},
  $data-grid-popover-default-item-background-color: transparent,
  $data-grid-popover-default-hovered-item-color:  #{css.theme-var(palette-primary-main)},
  $data-grid-popover-default-hovered-item-background-color: rgba(#{css.theme-var(palette-primary-mainChannel)} / #{css.theme-var(palette-action-hoverOpacity)}),

  $data-grid-popover-delete-item-color: #{css.theme-var(palette-error-main)},
  $data-grid-popover-delete-item-background-color: transparent,
  $data-grid-popover-delete-hovered-item-color: #{css.theme-var(palette-error-main)},
  $data-grid-popover-delete-hovered-item-background-color: rgba(#{css.theme-var(palette-error-mainChannel)} / #{css.theme-var(palette-action-hoverOpacity)}),

  $data-grid-popover-active-item-color: #{css.theme-var(palette-text-primary)},
  $data-grid-popover-active-item-background-color: #{css.theme-var(palette-primary-light)},
  $data-grid-popover-active-hovered-item-color: #{css.theme-var(palette-text-primary)},
  $data-grid-popover-active-hovered-item-background-color: #{css.theme-var(palette-primary-light)},

  $data-grid-popover-disabled-item-color: #{css.theme-var(palette-action-disabled)},
  $data-grid-popover-disabled-item-background-color: #{css.theme-var(palette-action-disabledBackground)},
  $data-grid-popover-disabled-hovered-item-color: rgba(#{css.theme-var(palette-action-activeChannel)} / 0.3),
  $data-grid-popover-disabled-hovered-item-background-color: #{css.theme-var(palette-action-disabledBackground)},

  // ***----- Scrollbar -----***
  $data-grid-scrollbar-track-color: #{css.theme-var(palette-app-specific-scrollbar-track-color)},
  $data-grid-scrollbar-thumb-color: #{css.theme-var(palette-app-specific-scrollbar-thumb-color)},

  // ***----- Switch -----***
  $data-grid-switch-text-color: #{css.theme-var(palette-text-tertiary)},
  $data-grid-switch-color: #{css.theme-var(palette-primary-main)},
  $data-grid-switch-background-color: #{css.theme-var(palette-background-default)},
  $data-grid-disabled-switch-text-color: #{css.theme-var(palette-text-disabled)},
  $data-grid-disabled-switch-color: #{css.theme-var(palette-action-disabled)},
  $data-grid-disabled-switch-background-color: #{css.theme-var(palette-background-default)},
  $data-grid-switch-wrapper-border-color: #{css.theme-var(palette-action-disabledBackground)},

  // ***----- Cell -----***
  $data-grid-cell-color: #{css.theme-var(palette-text-primary)},
  $data-grid-cell-background-color: #{css.theme-var(palette-background-paper)},
  $data-grid-cell-border-color:  #{css.theme-var(palette-app-specific-data-grid-cell-border-color)},
  $data-grid-selected-cell-background-color: #{css.theme-var(palette-app-specific-data-grid-selected-cell-background-color)},
  $data-grid-hovered-cell-background-color: #{css.theme-var(palette-app-specific-data-grid-hovered-cell-background-color)},
  $data-grid-grouped-row-cell-background-color: #{css.theme-var(palette-app-specific-data-grid-grouped-row-cell-background-color)},

  // ***----- Detailed Panel -----***
  $data-grid-detailed-panel-background-color: #{css.theme-var(palette-background-default)},

  // ***----- Row Grouping -----***
  $data-grid-row-group-toggler-color: #{css.theme-var(palette-primary-main)},
  $data-grid-row-group-toggler-background-color: transparent,
  $data-grid-row-group-toggler-hovered-color: #{css.theme-var(palette-primary-main)},
  $data-grid-row-group-toggler-hovered-background-color: transparent,


  // ***----- Cell Editor -----***
  $data-grid-save-action-color: #{css.theme-var(palette-primary-main)},
  $data-grid-save-action-background-color: #{css.theme-var(palette-primary-light)},
  $data-grid-disabled-save-action-color: #{css.theme-var(palette-action-disabled)},
  $data-grid-disabled-save-action-background-color: #{css.theme-var(palette-action-disabledBackground)},

  $data-grid-cancel-action-color: #{css.theme-var(palette-text-secondary)},
  $data-grid-cancel-action-background-color: transparent,
  $data-grid-disabled-cancel-action-color: #{css.theme-var(palette-action-disabled)},
  $data-grid-disabled-cancel-action-background-color: #{css.theme-var(palette-action-disabledBackground)},

);
